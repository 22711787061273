import { sendErrorNotification } from 'helpers/notifications';

export const updateItemFromCart = (items, itemId, quantity, descriptionId) => {
  let cartHaschanged = false;
  const newItem = { itemId, quantity };
  const newItemDescription = {
    itemId, quantity, descriptionId,
  };
  const cartItems = items.map((cartItem) => {
    if (cartItem.itemId === newItem.itemId) {
      if (descriptionId) {
        if (cartItem.descriptionId === newItemDescription.descriptionId) {
          cartHaschanged = true;
          return newItemDescription;
        }
        return cartItem;
      }
      cartHaschanged = true;
      return newItem;
    }
    return cartItem;
  }, items);
  if (!cartHaschanged) {
    if (descriptionId) {
      cartItems.push(newItemDescription);
    } else {
      cartItems.push(newItem);
    }
  }
  return cartItems;
};

export const removeItemFromCart = (items, itemId, descriptionId) => {
  if (descriptionId) {
    return items.filter((item) => item.descriptionId !== descriptionId || item.itemId !== itemId);
  }
  return items.filter((item) => item.itemId !== itemId);
};

export const updateCart = (items, itemId, quantity, descriptionId) => (quantity > 0
  ? updateItemFromCart(items, itemId, quantity, descriptionId)
  : removeItemFromCart(items, itemId, descriptionId));

export const isAbleToPurchase = (subtotal, minimumPurchase) => {
  const canPurchase = subtotal >= minimumPurchase;
  if (!canPurchase) {
    sendErrorNotification(`La compra mínima es de $${minimumPurchase}`);
  }
  return canPurchase;
};
