import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import { userPropTypes } from 'helpers/propTypes';

import './TopNav.scss';

const TopNav = (props) => {
  const {
    logout, openLoginModal, openRegisterModal, user,
  } = props;

  const renderUserLoggedOutButtons = () => (
    <div className="col-md-4 top-nav-user-actions" data-testid="top-nav-user-actions">
      <span className="top-nav-item top-nav-border-right" onClick={openRegisterModal} role="presentation">
        Crear cuenta
      </span>
      <span className="top-nav-item" onClick={openLoginModal} role="presentation">
        Iniciar sesi&oacute;n
      </span>
    </div>
  );

  const renderUserLoggedInButtons = () => (
    <div className="col-md-4 top-nav-user-actions" data-testid="top-nav-user-actions">
      <NavLink className="top-nav-item top-nav-border-right" to="/perfil">
        <i className="fa fa-user" aria-hidden="true" />
        {' '}
        Mi cuenta
      </NavLink>
      <span className="top-nav-item" role="presentation" onClick={logout}>
        Cerrar sesi&oacute;n
      </span>
    </div>
  );

  return (
    <div className="top-nav" data-testid="topnav">
      <div className="container fill-height">
        <div className="row top-nav-content fill-height">
          <div className="col-md-4 top-nav-info">
            <span className="top-nav-item top-nav-border-right">+54 9 11 3077-2781</span>
            <a className="top-nav-item" href="mailto:contacto@shuliclothes.com">
              contacto@shuliclothes.com
            </a>
          </div>
          <div className="col-md-4 top-nav-info text-center">
            <span className="top-nav-item">Distribuidora Mayorista</span>
          </div>
          {!user && renderUserLoggedOutButtons()}
          {user && renderUserLoggedInButtons()}
        </div>
      </div>
    </div>
  );
};

TopNav.defaultProps = {
  user: null,
};

TopNav.propTypes = {
  logout: PropTypes.func.isRequired,
  openLoginModal: PropTypes.func.isRequired,
  openRegisterModal: PropTypes.func.isRequired,
  user: userPropTypes,
};

export default TopNav;
