import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import Avatar from '@material-ui/core/Avatar';
import ShoppingCart from '@material-ui/icons/ShoppingCart';

import Input from 'components/commons/Input';
import Button from 'components/commons/Button';

import { DELIVERY_FREE, DELIVERY_PAY, DELIVERY_FREE_PAY } from './constants';
import truckImage from './assets/truck.png';

import './PaymentForm.scss';

const validate = (values) => {
  const errors = {};
  if (!values.delivery_method) {
    errors.delivery_method = 'Debe elegir un método de envío';
  }
  if (!values.comments) {
    errors.comments = 'Comentario inválido';
  }
  return errors;
};

const PaymentForm = (props) => {
  const {
    deliveryMethod, handleSubmit, onSubmit, submitting,
  } = props;

  return (
    <div className="container" data-testid="payment-form">
      <div className="row">
        <div className="form-container">
          <Avatar className="avatar">
            <ShoppingCart />
          </Avatar>
          <h4>M&eacute;todo de env&iacute;o</h4>
          <form className="redux-form" onSubmit={handleSubmit(onSubmit)}>
            <Field
              formGroupClassName={deliveryMethod === DELIVERY_FREE ? 'radio-active' : ''}
              name="delivery_method"
              label=""
              component={Input}
              type="radio"
              value={DELIVERY_FREE}
              renderRadioDiv={() => (
                <div className="radio-button-info">
                  <div className="radio-button-image">
                    <img src={truckImage} className="truck-image" alt="envio-CABA-lenceria" />
                  </div>
                  <div className="radio-button-text">
                    <span>Env&iacute;o por flete a CABA </span>
                  </div>
                </div>
              )}
            />
            <Field
              formGroupClassName={deliveryMethod === DELIVERY_FREE_PAY ? 'radio-active' : ''}
              name="delivery_method"
              label=""
              component={Input}
              type="radio"
              value={DELIVERY_FREE_PAY}
              renderRadioDiv={() => (
                <div className="radio-button-info">
                  <div className="radio-button-image">
                    <img src={truckImage} className="truck-image" alt="envio-CABA-interior-lenceria" />
                  </div>
                  <div className="radio-button-text">
                    <span>Env&iacute;o por flete a GBA</span>
                  </div>
                </div>
              )}
            />
            <Field
              formGroupClassName={deliveryMethod === DELIVERY_PAY ? 'radio-active' : ''}
              name="delivery_method"
              label=""
              component={Input}
              type="radio"
              value={DELIVERY_PAY}
              renderRadioDiv={() => (
                <div className="radio-button-info">
                  <div className="radio-button-image">
                    <img src={truckImage} className="truck-image" alt="envio-interior-lenceria" />
                  </div>
                  <div className="radio-button-text">
                    <span>Env&iacute;o por expresos al interior del pa&iacute;s</span>
                    <span>A abonar contra entrega</span>
                  </div>
                </div>
              )}
            />
            <Field
              component={Input}
              label="Transporte de preferencia / Comentarios"
              isTextArea
              name="comments"
            />
            <Button text="Finalizar pedido" type="submit" buttonClass="btn-block" disabled={submitting} />
          </form>
        </div>
      </div>
    </div>
  );
};

PaymentForm.defaultProps = {
  deliveryMethod: null,
};

PaymentForm.propTypes = {
  deliveryMethod: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

export default reduxForm({
  form: 'payment',
  validate,
})(PaymentForm);
