import * as constants from 'redux/constants/user.constants';

const defaultState = {
  data: null,
  loading: false,
  purchaseHistory: [],
};

const userReducer = (state = defaultState, action) => {
  const { data, type } = action;
  switch (type) {
    case constants.USER_LOGIN_REQUESTED:
    case constants.USER_REGISTER_REQUESTED:
    case constants.USER_CONTACT_REQUESTED:
    case constants.USER_RESET_PASSWORD_REQUESTED:
    case constants.USER_UPDATE_PROFILE_REQUESTED:
    case constants.USER_CHANGE_PASSWORD_REQUESTED:
    case constants.USER_GET_PURCHASE_HISTORY_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case constants.USER_GET_ORDER_PDF_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case constants.USER_LOGIN_SUCCEEDED:
    case constants.USER_PROFILE_SUCCEEDED:
    case constants.USER_REGISTER_SUCCEEDED:
    case constants.USER_UPDATE_PROFILE_SUCCEEDED:
      return {
        ...state,
        data: data.user,
        loading: false,
      };
    case constants.USER_GET_PURCHASE_HISTORY_SUCCEEDED:
      return {
        ...state,
        loading: false,
        purchaseHistory: data.purchaseHistory,
      };
    case constants.USER_GET_ORDER_PDF_SUCCEEDED:
      return {
        ...state,
        loading: false,
      };
    case constants.USER_CONTACT_SUCCEEDED:
    case constants.USER_RESET_PASSWORD_SUCCEEDED:
    case constants.USER_CHANGE_PASSWORD_SUCCEEDED:
    case constants.USER_CONTACT_FAILED:
    case constants.USER_RESET_PASSWORD_FAILED:
    case constants.USER_UPDATE_PROFILE_FAILED:
    case constants.USER_CHANGE_PASSWORD_FAILED:
    case constants.USER_GET_PURCHASE_HISTORY_FAILED:
      return {
        ...state,
        loading: false,
      };
    case constants.USER_LOGOUT_SUCCEEDED:
    case constants.USER_LOGIN_FAILED:
    case constants.USER_PROFILE_FAILED:
    case constants.USER_REGISTER_FAILED:
      return defaultState;
    case constants.USER_LOGOUT_FAILED:
    default:
      return state;
  }
};

export default userReducer;
