import React from 'react';
import { Link } from 'react-router-dom';

import logoImage from './assets/logo.png';

import './Logo.scss';

const Logo = () => (
  <div className="logo-container" data-testid="logo">
    <Link to="/home">
      <img src={logoImage} className="logo-image" alt="Shuli-Clothes" />
    </Link>
  </div>
);

export default Logo;
