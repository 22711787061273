import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Carousel } from 'react-responsive-carousel';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Zoom from 'react-medium-image-zoom';

import { productPropTypes } from 'helpers/propTypes';
import { getCookie, setCookie } from 'helpers/cookies';
import { setVisitsProducts } from 'services/user.services';

import DescriptionItem from 'components/commons/DescriptionItem';
import QuantitySelector from 'components/commons/QuantitySelector';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'react-medium-image-zoom/dist/styles.css';
import './Product.scss';

const Product = (props) => {
  const {
    images, item, onProductClick, onProductCopyClick, onQuantityChange, quantity,
    singleProductFullUrl, cartItemDescriptions, colors, combinations,
  } = props;

  useEffect(() => {
    updateCookie();
  });

  const updateCookie = async () => {
    try {
      if (getCookie('visit_product')) {
        const arrCount = getCookie('visit_product');
        addProduct(arrCount);
      } else {
        const arrCount = [];
        addProduct(arrCount);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const addProduct = async (arrCount) => {
    const existProduct = arrCount.includes(item.id);
    if (!existProduct) {
      arrCount.push(item.id);
      setCookie('visit_product', arrCount, { maxAge: 86400 });
      const data = {
        id: item.id,
      };
      await setVisitsProducts(data);
    }
  };

  const renderProductQuantitySelector = () => {
    if (item.stock_product_descriptions.length > 0) {
      return (
        <ul className="product-details-stock">
          {Object.keys(combinations)?.map((id) => {
            const color = colors.find(({ stock_description_id }) => stock_description_id === Number(id));
            const descriptions = combinations[id];
            return (
              <div className="product-details-stock-option" key={`${item.id}-${id}`}>
                <li className="clearfix">
                  <DescriptionItem
                    description={color}
                    onQuantityChange={onQuantityChange}
                    showQuantitySelector={descriptions.length === 0}
                    storage={cartItemDescriptions.find((data) => (data.descriptionId === color.id))}
                  />
                </li>
                {descriptions.map((description) => (
                  <Fragment key={`${item.id}-${description.id}`}>
                    {description.stock > 0 && (
                      <li className="float-xs-left">
                        <DescriptionItem
                          description={description}
                          onQuantityChange={onQuantityChange}
                          storage={cartItemDescriptions.find((data) => (data.descriptionId === description.id))}
                        />
                      </li>
                    )}
                  </Fragment>
                ))}
              </div>
            );
          })}
        </ul>
      );
    }

    if (item.stock > 0) {
      return (
        <QuantitySelector
          limit={item.stock}
          onQuantityChange={onQuantityChange}
          quantity={quantity}
        />
      );
    }

    return <span className="product-details-out-of-stock">Sin stock</span>;
  };

  return (
    <div data-testid="product-modal">
      <div className="container">
        <div className="row">
          <div className="col-lg-5">
            <div className="product-details-image-container">
              <Carousel
                autoPlay
                infiniteLoop
                showIndicators={false}
                showStatus={false}
                showThumbs
                swipeable={false}
              >
                {images.map((image, index) => (
                  <Zoom key={`${image.id}-${index}`}>
                    <img alt={item.name} src={image} width="250" />
                  </Zoom>
                ))}
              </Carousel>
            </div>
          </div>
          <div className="col-lg-7">
            <div className="product-details">
              <span
                className="product-details-name"
                onClick={onProductClick}
                role="presentation"
              >
                {item.name}
              </span>
              <CopyToClipboard
                text={singleProductFullUrl}
                onCopy={onProductCopyClick}
              >
                <i
                  className="fa fa-clone product-details-copy-icon"
                  aria-hidden="true"
                  title="Copiar link del producto"
                />
              </CopyToClipboard>
              {item.sale ? <div style={{ color: 'red' }}>{` ${Math.round(100 - (item.sale / item.price) * 100)}% OFF `}</div> : <div />}
              <div className="product-details-section">
                <div className="product-details-price">
                  {`$${item.sale ? item.sale.toLocaleString('de-DE') : item.price.toLocaleString('de-DE')}`}
                  {!!item.sale && <span>{`$${item.price.toLocaleString('de-DE')}`}</span>}
                </div>
              </div>
              <div className="separator" />
              <div className="product-details-section">
                {renderProductQuantitySelector()}
              </div>
              <div className="product-details-section">
                <span className="product-details-description">{item.description}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Product.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
  cartItemDescriptions: PropTypes.array.isRequired,
  item: productPropTypes.isRequired,
  onProductClick: PropTypes.func.isRequired,
  onProductCopyClick: PropTypes.func.isRequired,
  onQuantityChange: PropTypes.func.isRequired,
  quantity: PropTypes.number.isRequired,
  singleProductFullUrl: PropTypes.string.isRequired,
};

export default Product;
