import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { productsArrayPropTypes, slidersArrayPropTypes } from 'helpers/propTypes';
import { MOST_SELLED, NEW_ARRIVALS, OFFERS } from 'views/Home/constants';

import ItemContainer from 'containers/ItemContainer';
import Home from 'views/Home';

const HomeContainer = (props) => {
  const {
    loading, minimumPurchase, mostSelled, newArrivals, offers, section, sliders,
  } = props;
  const [items, setItems] = useState(offers);
  const [currentSection, setCurrentSeccion] = useState(NEW_ARRIVALS);

  useEffect(() => {
    // eslint-disable-next-line no-use-before-define
    setNewSection(section);
    // eslint-disable-next-line
  }, [mostSelled, newArrivals, offers]);

  const setNewSection = (newSection) => {
    switch (newSection) {
      case OFFERS.value:
        setCurrentSeccion(OFFERS);
        setItems(offers);
        break;
      case MOST_SELLED.value:
        setCurrentSeccion(MOST_SELLED);
        setItems(mostSelled);
        break;
      default:
        setCurrentSeccion(NEW_ARRIVALS);
        setItems(newArrivals);
        break;
    }
  };

  const renderItem = (item) => (
    <div className="col-product" style={{ background: 'rgb(249 249 249)' }} key={`item-${item.id}`}>
      <ItemContainer item={item} />
    </div>
  );

  return (
    <Home
      currentSection={currentSection}
      items={items}
      loading={loading}
      minimumPurchase={minimumPurchase}
      onSectionClick={setNewSection}
      renderItem={renderItem}
      sliders={sliders}
    />
  );
};

const mapStateToProps = (state, props) => ({
  loading: state.product.loading,
  minimumPurchase: state.app.minimumPurchase,
  mostSelled: state.product.mostSelled,
  newArrivals: state.product.newArrivals,
  offers: state.product.offers,
  section: props.match.params.section,
  sliders: state.app.sliders,
});

HomeContainer.defaultProps = {
  section: '',
};

HomeContainer.propTypes = {
  loading: PropTypes.bool.isRequired,
  minimumPurchase: PropTypes.number.isRequired,
  mostSelled: productsArrayPropTypes.isRequired,
  newArrivals: productsArrayPropTypes.isRequired,
  offers: productsArrayPropTypes.isRequired,
  section: PropTypes.string,
  sliders: slidersArrayPropTypes.isRequired,
};

export default connect(mapStateToProps)(HomeContainer);
