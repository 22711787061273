import React from 'react';
import PropTypes from 'prop-types';

import { CircularProgress } from '@material-ui/core';

import './Loading.scss';

const Loading = (props) => {
  const { message, size } = props;
  return (
    <div className="loading">
      <CircularProgress data-testid="loader" size={size} />
      {message && <span className="loading-text">{message}</span>}
    </div>
  );
};

Loading.defaultProps = {
  message: null,
  size: 65,
};

Loading.propTypes = {
  message: PropTypes.string,
  size: PropTypes.number,
};

export default Loading;
