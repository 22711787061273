import React from 'react';
import PropTypes from 'prop-types';

import { productPropTypes } from 'helpers/propTypes';

import QuantitySelector from 'components/commons/QuantitySelector';

import './Item.scss';

const Item = (props) => {
  const {
    item, onItemClick, onQuantityChange, quantity,
  } = props;

  const renderProductButton = () => {
    if (item.stock_product_descriptions.length > 0) {
      return (
        <div className="product-quantity-selector">
          <button type="button" onClick={onItemClick}>Ver detalles</button>
        </div>
      );
    }

    if (item.stock > 0) {
      return (
        <div className="product-quantity-selector">
          <QuantitySelector
            limit={item.stock}
            onQuantityChange={onQuantityChange}
            quantity={quantity}
          />
        </div>
      );
    }

    return <span className="product-out-of-stock">Sin stock</span>;
  };

  return (
    <div className="product-item" data-testid={`product-${item.id}`}>
      <div className="product">
        <div className="product-image" onClick={onItemClick} role="presentation">
          <img src={item.image1} alt={item.name} />
        </div>
        <div className="product-info">
          <h6 className="product-name" onClick={onItemClick} role="presentation">
            {item.name}
            <div className="product-price">
              {item.sale ? <div style={{ color: 'red' }}>{` ${Math.round(100 - (item.sale / item.price) * 100)}% OFF `}</div> : <div />}
              <br />
              {`$${item.sale ? item.sale.toLocaleString('de-DE') : item.price.toLocaleString('de-DE')}`}
              {!!item.sale && <span data-testid="product-on-sale">{`$${item.price.toLocaleString('de-DE')}`}</span>}
            </div>
          </h6>
          {renderProductButton()}
        </div>
      </div>
    </div>
  );
};

Item.propTypes = {
  item: productPropTypes.isRequired,
  onItemClick: PropTypes.func.isRequired,
  onQuantityChange: PropTypes.func.isRequired,
  quantity: PropTypes.number.isRequired,
};

export default Item;
