import { removeLocalStorage } from 'helpers/storage';
import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';
import * as constants from 'redux/constants/cart.constants';
import { delivery, pickup, validateCoupon } from 'services/cart.services';

export function* checkoutDelivery(action) {
  try {
    const data = yield call(delivery, action);
    yield put({ type: constants.CART_CHECKOUT_SUCCEEDED, data });
    removeLocalStorage('descriptionsCart');
  } catch (error) {
    yield put({ type: constants.CART_CHECKOUT_FAILED, error });
  }
}

export function* checkoutPickup(action) {
  try {
    const data = yield call(pickup, action);
    yield put({ type: constants.CART_CHECKOUT_SUCCEEDED, data });
    removeLocalStorage('descriptionsCart');
  } catch (error) {
    yield put({ type: constants.CART_CHECKOUT_FAILED, error });
  }
}

export function* checkCoupon(action) {
  try {
    const data = yield call(validateCoupon, action.coupon, action.email);
    yield put({ type: constants.CART_COUPON_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.CART_COUPON_FAILED, error });
  }
}

export function* watchCart() {
  yield all([
    takeLatest(constants.CART_CHECKOUT_PICKUP_REQUESTED, checkoutPickup),
    takeLatest(constants.CART_CHECKOUT_DELIVERY_REQUESTED, checkoutDelivery),
    takeLatest(constants.CART_COUPON_REQUESTED, checkCoupon),
  ]);
}
