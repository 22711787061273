import * as userConstants from 'redux/constants/user.constants';
import * as cartConstants from 'redux/constants/cart.constants';
import * as productConstants from 'redux/constants/product.constants';
import { saveUserCookies, removeUserCookies } from 'helpers/cookies';
import {
  getLocalStorage, setLocalStorage, removeLocalStorage, clearLocalStorage,
} from 'helpers/storage';
import { updateCart } from 'helpers/cart';
import { sendSuccessNotification, sendErrorNotification } from 'helpers/notifications';

const dispatchCartChanged = (store, items) => {
  const { dispatch } = store;
  setLocalStorage('lenceria-cart', items);
  dispatch({
    type: cartConstants.CART_UPDATE_SUCCEEDED,
    items,
  });
};

const updateCartItems = (store, action) => {
  const { cart } = store.getState();
  const items = updateCart(
    cart.items, action.itemId, action.quantity, action.descriptionId,
  );
  dispatchCartChanged(store, items);
  if (action.showNotification) {
    if (action.quantity > 0) sendSuccessNotification('Producto agregado al carrito correctamente');
    else sendErrorNotification('Producto eliminado del carrito correctamente');
  }
};

const initializeCart = (store, products) => {
  const items = getLocalStorage('lenceria-cart') || [];
  const cart = [];
  items.forEach(({
    itemId, quantity, descriptionId,
  }) => {
    const product = products.find((prod) => prod.id === itemId, null);
    if (product) {
      cart.push({
        itemId: product.id,
        quantity: quantity > product.stock ? product.stock : quantity,
        descriptionId,
      });
    }
  });
  dispatchCartChanged(store, cart);
};

const storageMiddleware = (store) => (next) => (action) => {
  const { data, type } = action;
  switch (type) {
    case productConstants.PRODUCT_GET_ALL_SUCCEEDED:
      initializeCart(store, data.items);
      break;
    case userConstants.USER_LOGIN_SUCCEEDED:
    case userConstants.USER_REGISTER_SUCCEEDED:
      saveUserCookies(data.token);
      break;
    case cartConstants.CART_UPDATE_REQUESTED:
      updateCartItems(store, action);
      break;
    case cartConstants.CART_CHECKOUT_SUCCEEDED:
      removeLocalStorage('lenceria-cart');
      break;
    case userConstants.USER_LOGOUT_SUCCEEDED:
      clearLocalStorage();
      removeUserCookies();
      break;
    default:
      break;
  }
  return next(action);
};

export default storageMiddleware;
