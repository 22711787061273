import React from 'react';
import PropTypes from 'prop-types';

import QuantitySelector from 'components/commons/QuantitySelector';

import './DescriptionItem.scss';

const DescriptionItem = (props) => {
  const {
    description, onQuantityChange, showQuantitySelector, storage,
  } = props;
  const quantity = storage ? storage.quantity : 0;

  return description.stock_descriptions.stock_category_id === 1 && description.stock_descriptions.rgb ? (
    <>
      <div className="color-container">
        <span className="color-circle" style={{ backgroundColor: description.stock_descriptions.rgb }} />
        <span className="color-name">{description.stock_descriptions.name}</span>
      </div>
      {showQuantitySelector && (
        <QuantitySelector
          limit={description.stock}
          onQuantityChange={onQuantityChange}
          quantity={quantity}
          description={description}
        />
      )}
    </>
  ) : (
    <div className="d-flex flex-column align-items-center">
      <span className="description-name">{description.stock_descriptions.name}</span>
      <QuantitySelector
        limit={description.stock}
        onQuantityChange={onQuantityChange}
        quantity={quantity}
        description={description}
      />
    </div>
  );
};

DescriptionItem.defaultProps = {
  showQuantitySelector: false,
  storage: null,
};

DescriptionItem.propTypes = {
  description: PropTypes.object.isRequired,
  onQuantityChange: PropTypes.func.isRequired,
  showQuantitySelector: PropTypes.bool,
  storage: PropTypes.shape({
    quantity: PropTypes.number,
  }),
};

export default DescriptionItem;
