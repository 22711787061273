import { createSelector } from 'reselect';
import { getProducts } from 'redux/selectors/product.selector';

const getCartItems = (state) => state.cart.items;
const getItem = (state, props) => props.item;

export const itemQuantitySelector = createSelector([getCartItems, getItem],
  (cartItems, item) => (
    cartItems.find((cartItem) => cartItem.itemId === item.id, null)
  ));

export const itemsQuantitySelector = createSelector([getCartItems, getItem],
  (cartItems, item) => (
    cartItems.filter((cartItem) => cartItem.itemId === item.id, null)
  ));

export const cartSizeSelector = createSelector([getCartItems], (items) => (
  items.reduce((a, b) => a + b.quantity, 0)
));

export const cartItemsSelector = createSelector([getProducts, getCartItems],
  (products, cartItems) => {
    const items = {};
    cartItems.forEach(({ itemId, quantity, descriptionId }) => {
      const item = products.find((product) => product.id === itemId, null);
      if (item) {
        if (item.stock_product_descriptions.length > 0) {
          const colors = item.stock_product_descriptions.filter(
            ({ stock_descriptions }) => stock_descriptions.stock_category_id === 1,
          );
          let cartItem = items[itemId];
          if (!cartItem) {
            cartItem = {
              item, itemId, quantity, colors, descriptions: {},
            };
          }
          const description = item.stock_product_descriptions.find(
            ({ id }) => id === descriptionId,
          );
          cartItem.descriptions[descriptionId] = { description, quantity };
          items[itemId] = cartItem;
        } else {
          items[itemId] = {
            item, itemId, quantity, colors: undefined, descriptions: undefined,
          };
        }
      }
    });
    const array = Object.values(items).map(({
      item, quantity, colors, descriptions,
    }) => {
      if (colors && descriptions) {
        const cartQuantity = Object.values(descriptions).reduce(
          (a, b) => a + Number(b.quantity),
          0,
        );
        const combinations = colors?.reduce((a, b) => {
          a[b.stock_description_id] = a[b.stock_description_id] || [];
          const combination1 = item?.stock_product_descriptions
            ?.filter(({ stock_descriptions }) => stock_descriptions.stock_category_id !== 1)
            ?.find(({ combination }) => combination === b.combination);
          const combination2 = item?.stock_product_descriptions
            ?.filter(({ stock_descriptions }) => stock_descriptions.stock_category_id === 1)
            ?.find(({ combination }) => combination === b.combination);
          if (descriptions[(combination1 || combination2).id]) {
            a[b.stock_description_id].push(descriptions[(combination1 || combination2).id]);
          }
          return a;
        }, Object.create(null));
        return {
          item, itemId: item.id, quantity: cartQuantity, colors, descriptions: combinations,
        };
      }
      return {
        item, itemId: item.id, quantity, colors, descriptions,
      };
    });
    return array;
  });

export const cartSubtotalSelector = createSelector([getProducts, getCartItems],
  (products, cartItems) => {
    const total = cartItems.reduce((a, b) => {
      const cartItem = products.find((product) => product.id === b.itemId, null);
      let price = 0;
      if (cartItem) {
        price = cartItem.sale ? cartItem.sale * b.quantity : cartItem.price * b.quantity;
      }
      return a + price;
    }, 0);
    return total;
  });
