export const APP_INITIALIZE_REQUESTED = 'APP_INITIALIZE_REQUESTED';

export const APP_DISCOUNT_REQUESTED = 'APP_DISCOUNT_REQUESTED';
export const APP_DISCOUNT_SUCCEEDED = 'APP_DISCOUNT_SUCCEEDED';
export const APP_DISCOUNT_FAILED = 'APP_DISCOUNT_FAILED';

export const APP_MINIMUM_PURCHASE_REQUESTED = 'APP_MINIMUM_PURCHASE_REQUESTED';
export const APP_MINIMUM_PURCHASE_SUCCEEDED = 'APP_MINIMUM_PURCHASE_SUCCEEDED';
export const APP_MINIMUM_PURCHASE_FAILED = 'APP_MINIMUM_PURCHASE_FAILED';

export const APP_SLIDERS_GET_ALL_REQUESTED = 'APP_SLIDERS_GET_ALL_REQUESTED';
export const APP_SLIDERS_GET_ALL_SUCCEEDED = 'APP_SLIDERS_GET_ALL_SUCCEEDED';
export const APP_SLIDERS_GET_ALL_FAILED = 'APP_SLIDERS_GET_ALL_FAILED';

export const APP_WHATSAPP_REQUESTED = 'APP_WHATSAPP_REQUESTED';
export const APP_WHATSAPP_SUCCEEDED = 'APP_WHATSAPP_SUCCEEDED';
export const APP_WHATSAPP_FAILED = 'APP_WHATSAPP_FAILED';
