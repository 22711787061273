import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { productsArrayPropTypes } from 'helpers/propTypes';
import history from 'helpers/history';
import { openProductModal } from 'redux/actions/modal.actions';

import Modal from 'components/commons/Modal';
import SearchForm from 'components/forms/SearchForm';

const SearchContainer = (props) => {
  const {
    onClose, openProductModalAction, products,
  } = props;
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);

  const removeAccents = (str) => str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

  const onChange = (formData) => {
    let { search } = formData;
    if (search !== undefined) {
      search = (removeAccents(search));
    }
    if (!search) {
      setItems([]);
      return;
    }

    const value = search.trim().toLowerCase();
    // Start the search with 3 valid characters
    if (value.length < 3) {
      setItems([]);
      return;
    }

    setLoading(true);

    const filtered = products.filter((product) => {
      const name = (removeAccents(product.name));
      let tags = false;
      if (!!product.tags.length > 0) {
        tags = product.tags.some((element) => element.name.toString().includes(value));
      }
      return name.toLowerCase().indexOf(value) !== -1 || tags;
    });
    setItems(filtered);
    setLoading(false);
  };

  const onSubmit = (formData) => {
    let { search } = formData;
    if (search !== undefined) {
      search = (removeAccents(search));
    }
    const value = search.trim().toLowerCase();
    onClose();
    history.push(`/busqueda/${value}`);
  };

  return (
    <Modal loading={loading} onClose={onClose}>
      <SearchForm
        items={items}
        loading={loading}
        onChange={onChange}
        openProductModal={openProductModalAction}
        onSubmit={onSubmit}
      />
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  products: state.product.items,
});

const mapDispatchToProps = (dispatch) => ({
  openProductModalAction: bindActionCreators(openProductModal, dispatch),
});

SearchContainer.propTypes = {
  onClose: PropTypes.func.isRequired,
  openProductModalAction: PropTypes.func.isRequired,
  products: productsArrayPropTypes.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchContainer);
