import { get } from 'services/api';

export const getMinimumPurchase = async () => {
  const response = await get('minimumAmount');
  return response;
};

export const getDiscount = async () => {
  const response = await get('discount');
  return response;
};

export const getAllSliders = async () => {
  const response = await get('sliders');
  return response;
};

export const getWhatsapp = async () => {
  const response = await get('whatsapp');
  return response;
};
